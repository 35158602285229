/* 修改账户信息 账号和密码分别修改 1、租户管理员：可修改账户和密码 2、分公司管理员可修改账户 3、普通员工仅可查看 */
<template>
	<div>
		<gc-dialog title="修改账户信息" :show.sync="visible" @cancel="cancelModify" @ok="confirmModify">
			<template>
				<el-form
					style="padding: 0 20px"
					:model="modelForm"
					:rules="rules"
					ref="accountForm"
					label-position="top"
					label-width="100px"
					class="dialog-form-s"
					v-if="visible"
				>
					<el-form-item label="登录账号" prop="loginAccount" ref="account">
						<el-input
							size="small"
							v-model="modelForm.loginAccount"
							placeholder="请输入"
							:disabled="!accountEdit"
						></el-input>
						<el-button
							plain
							v-if="!accountEdit"
							@click="handleAccountEditClick"
							v-has="'utos:tosm:staff:account:update'"
						>
							修改
						</el-button>
						<template v-else>
							<el-button type="primary" @click="handleAccountModify">确认</el-button>
							<el-button type="info" plain @click="handleAccountCancelEdit">取消</el-button>
						</template>
					</el-form-item>
					<el-form-item label="登录密码" prop="newPwd" ref="pwd">
						<el-input
							type="password"
							size="small"
							show-password
							:disabled="!pwdEdit"
							v-model="modelForm.newPwd"
							:placeholder="'请输入' || '********'"
						></el-input>
						<el-button
							plain
							v-if="!pwdEdit"
							@click="handlePwdEditClick"
							v-has="'utos:tosm:staff:account:update'"
						>
							修改
						</el-button>
						<template v-else>
							<el-button type="primary" @click="handlePwdModify">确认</el-button>
							<el-button type="info" plain @click="handlePwdCancelEdit">取消</el-button>
						</template>
					</el-form-item>
				</el-form>
			</template>
			<template #footer>
				<el-button @click="visible = false" round>取消</el-button>
			</template>
		</gc-dialog>
	</div>
</template>

<script>
import { ruleRequired, ruleMaxLength, ruleMinLength, ruleComplexPassValidate } from '@/utils/rules'
import { encrypt } from '@/utils'
import { apiModifyStaffAccount, apiModifyStaffPwd } from '@/apis/organizeStaff.api'
export default {
	name: 'ModifyAccount',
	data() {
		return {
			visible: false,
			modelForm: {
				loginAccount: '',
				newPwd: 'jk300349',
			},
			rules: {
				loginAccount: [ruleRequired('请输入登录名'), ruleMaxLength(32)],
				newPwd: [
					// 待办：需要加密码等级判断吗
					ruleRequired('请输入密码'),
					ruleMinLength(6),
					ruleMaxLength(18, '密码长度'),
					ruleComplexPassValidate(),
				],
			},
			originInfo: {
				account: '',
				password: '',
			},
			accountEdit: false,
			pwdEdit: false,
			staffInfo: {},
		}
	},
	computed: {
		canModifyAccount() {
			return true
		},
		canModifyPassword() {
			return true
		},
	},
	watch: {
		visible(val) {
			if (!val) {
				this.accountEdit = false
				this.pwdEdit = false
				this.$refs.accountForm?.resetFields()
				this.modelForm = this.$options.data().modelForm
			}
		},
	},
	methods: {
		handleAccountModify() {
			this.$refs.accountForm.validateField('loginAccount', valid => {
				if (!valid) {
					apiModifyStaffAccount({
						account: this.modelForm.loginAccount,
						staffId: this.staffInfo.id,
					}).then(() => {
						this.$message.success('修改账号成功')
						this.visible = false
						this.$emit('refresh-staff-list')
					})
				}
			})
		},
		handlePwdModify() {
			this.$refs.accountForm.validateField('newPwd', valid => {
				if (!valid) {
					apiModifyStaffPwd({
						...this.modelForm,
						staffId: this.staffInfo.id,
						newPwd: encrypt(this.modelForm.newPwd),
					}).then(() => {
						this.$message.success('修改密码成功')
						this.visible = false
						this.$emit('refresh-staff-list')
					})
				}
			})
		},
		handleAccountCancelEdit() {
			this.accountEdit = false
			this.$set(this.modelForm, 'loginAccount', this.originInfo.account)
		},
		handlePwdCancelEdit() {
			this.pwdEdit = false
			this.$set(this.modelForm, 'newPwd', this.originInfo.password)
		},
		handleAccountEditClick() {
			this.accountEdit = true
		},
		handlePwdEditClick() {
			this.pwdEdit = true
			this.$set(this.modelForm, 'newPwd', '')
			this.$nextTick(() => {
				this.$refs.accountForm.clearValidate('newPwd')
			})
		},
		edit(staffInfo) {
			this.$set(this.modelForm, 'loginAccount', staffInfo.account)
			console.log('edit: ', staffInfo.account, this.modelForm.loginAccount)

			this.staffInfo = staffInfo
			this.originInfo.account = staffInfo.account
			this.visible = true
		},
		confirmModify() {
			this.visible = false
		},
		cancelModify() {
			this.visible = false
		},
	},
}
</script>
<style lang="scss" scoped>
::v-deep .el-form-item__content {
	display: flex;
	.el-button {
		margin-left: 10px;
		line-height: 32px;
		height: 32px;
		padding: 0 10px;

		&.is-plain {
			background: transparent !important;
			&.el-button--info {
				color: #4c4c4c !important;
			}
			&.el-button--primary {
				color: #2f87fe !important;
			}
		}
	}
}
</style>
