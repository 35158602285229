/* 待办： 1、部门类型options (新增子级部门options.length>0、新增本级部门部门类型自动带入不可修改) 2、部门负责人options
3、所在省份、市options 4、省份加入apiCache */

<template>
	<div class="update-department">
		<gc-dialog
			:title="isEdit ? '编辑部门' : '新增部门'"
			:show.sync="visible"
			@cancel="cancelUpdate"
			@ok="confirmUpdate"
			width="610px"
		>
			<el-form
				:model="modelForm"
				:rules="departmentRule"
				ref="modelForm"
				label-position="top"
				label-width="100px"
				class="dialog-form-s"
			>
				<el-form-item label="部门名称" prop="name">
					<el-input size="small" v-model="modelForm.name" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="部门类型" prop="orgStructId">
					<el-select
						size="small"
						v-model="modelForm.orgStructId"
						placeholder="请选择"
						@change="handleOrgTypeChange"
						:disabled="isEdit"
					>
						<el-option
							v-for="item in orgTypeList"
							:key="item.id"
							:label="item.name"
							:value="item.id"
							@click.native="modelForm.pid = ''"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="父级部门" prop="pid" :rules="pidRules" v-if="!isOrgFirstLevel">
					<el-select size="small" v-model="modelForm.pid" placeholder="请选择" :disabled="isEdit">
						<el-option
							v-for="item in options.pOrgList"
							:key="item.id"
							:label="item.name"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="负责人" prop="additionalInfo.principal">
					<el-input size="small" v-model="modelForm.additionalInfo.principal" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="手机号码" prop="additionalInfo.phone">
					<el-input
						size="small"
						:maxlength="11"
						v-model="modelForm.additionalInfo.phone"
						placeholder="请输入"
					></el-input>
				</el-form-item>
				<el-form-item label="所在省份" prop="additionalInfo.province">
					<el-select
						size="small"
						v-model="modelForm.additionalInfo.province"
						placeholder="请选择"
						@change="handleProvinceChange"
					>
						<el-option
							v-for="item in provinceList"
							:key="item.regionCode"
							:label="item.regionName"
							:value="item.regionCode"
							@click.native="modelForm.additionalInfo.city && (modelForm.additionalInfo.city = '')"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="所在市" prop="additionalInfo.city">
					<el-select size="small" v-model="modelForm.additionalInfo.city" placeholder="请选择">
						<el-option
							v-for="item in options.cityOptions"
							:key="item.regionCode"
							:label="item.regionName"
							:value="item.regionCode"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="地址" class="col-12" prop="additionalInfo.location">
					<el-input
						size="small"
						v-model="modelForm.additionalInfo.location"
						placeholder="请输入地址"
					></el-input>
				</el-form-item>
			</el-form>
		</gc-dialog>
	</div>
</template>

<script>
import { ruleRequired, ruleMaxLength, RULE_PHONE } from '@/utils/rules'
import { apiAddOrg, apiModifyOrg } from '@/apis/organizeStaff.api'
import { apiGetCityList } from '@/apis/account.api'
import { apiGetOrgStruct, apiGetParentDepartment } from '@/apis/organizeStaff.api'
export default {
	name: 'UpdateDepartment',
	components: {},
	props: {
		treeData: {
			type: Array,
			default: () => [],
		},
	},

	data() {
		return {
			visible: false,
			isEdit: false,
			modelForm: {
				name: '', // 部门名称
				orgStructCode: '', // 部门编号
				orgStructId: '', // 部门类型
				pid: '',
				additionalInfo: {
					principal: '', // 负责人
					phone: '', // 手机号码
					province: '', // 所在省份
					city: '', // 所在市
					location: '', // 地址
				},
			},
			departmentRule: {
				name: [ruleRequired('请输入部门名称'), ruleMaxLength(20)],
				orgStructId: [ruleRequired('请选择部门类型')],
				'additionalInfo.principal': [ruleRequired('请输入负责人'), ruleMaxLength(24)],
				'additionalInfo.phone': [ruleRequired('请输入手机号'), RULE_PHONE],
				'additionalInfo.province': [ruleRequired('请选择省份')],
				'additionalInfo.city': [ruleRequired('请选择市')],
				'additionalInfo.location': [ruleMaxLength(32)],
			},
			selectOrgId: '',
			options: {
				departmentOptions: [],
				provinceList: [],
				cityOptions: [],
				pOrgList: [],
			},
			levelStruct: {},
			orgIdStruct: {},

			citySet: {},
		}
	},
	computed: {
		userInfo() {
			return this.$store.getters.userInfo
		},
		orgTypeList() {
			return this.treeData.length === 0 ? this.levelStruct[0] : this.options.orgTypeList
		},
		isOrgFirstLevel() {
			const { orgStructId } = this.modelForm
			const levelOne = this.levelStruct[0]
			if (!levelOne || levelOne.length === 0 || !orgStructId) return true
			return levelOne.filter(item => item.id === orgStructId).length > 0
		},
		pidRules() {
			return this.isOrgFirstLevel ? [] : [ruleRequired('请选择父级部门')]
		},
		provinceList() {
			return this.$store.getters.provinceList
		},
		provinceSet() {
			const set = {}
			this.provinceList.map(item => {
				set[item.regionCode] = item.regionName
			})
			return set
		},
	},
	watch: {
		visible(newVal) {
			if (!newVal) {
				this.$refs.modelForm?.resetFields()
				this.modelForm = this.$options.data().modelForm
			}
		},
	},
	mounted() {
		this.$store.dispatch('apiCache/getProvinceList')
		this.getOrgTypeList()
	},
	methods: {
		getOrgTypeList() {
			apiGetOrgStruct({ tenantId: this.userInfo.tenantId })
				.then(res => {
					this.handleOrgStructData(res.children)
				})
				.catch(() => this.$message.error('获取组织结构失败'))
		},
		handleOrgStructData(data) {
			const arr = []
			const helper = (data, level) => {
				data.forEach(item => {
					const { children, ...rest } = item

					arr.push(rest)

					const list = this.levelStruct[level] ? this.levelStruct[level].push(rest) : [rest]
					this.$set(this.levelStruct, level, list)
					this.$set(this.orgIdStruct, rest.id, rest)
					if (children) helper(children, level + 1)
				})
			}
			helper(data, 0)
			this.$set(this.options, 'orgTypeList', arr)
		},
		// 部门类型修改、控制父级部门是否必填
		handleOrgTypeChange(val) {
			if (!val) return
			apiGetParentDepartment({ orgStructId: val }).then(({ dataList }) => {
				this.$set(this.options, 'pOrgList', dataList)
			})
		},
		handleCityChange(val) {
			this.$set(this.modelForm, 'additionalInfo.cityName', this.citySet[val])
		},
		handleProvinceChange(val) {
			if (!val) return
			apiGetCityList(val).then(({ records }) => {
				records.map(item => {
					this.$set(this.citySet, item.regionCode, item.regionName)
				})
				this.$set(this.options, 'cityOptions', records)
			})
		},
		add() {
			this.isEdit = false
			this.visible = true
		},
		edit(initialInfo) {
			this.modelForm = this._.cloneDeep({
				...initialInfo,
				orgStructId: initialInfo.orgStruct.id,
				additionalInfo: initialInfo.additionalInfo || {},
			})
			this.handleOrgTypeChange(this.modelForm.orgStructId)
			this.handleProvinceChange(this.modelForm.additionalInfo.province)
			this.isEdit = true
			this.visible = true
		},
		confirmUpdate() {
			this.$refs.modelForm.validate(valid => {
				if (valid) {
					const req = !this.isEdit ? apiAddOrg : apiModifyOrg
					const { additionalInfo } = this.modelForm
					const { province, city } = additionalInfo
					additionalInfo.provinceName = this.provinceSet[province]
					additionalInfo.cityName = this.citySet[city]
					req({
						...this.modelForm,
						additionalInfo,
						orgStructCode: this.orgIdStruct[this.modelForm.orgStructId]?.code,
					}).then(() => {
						this.$message.success(this.isEdit ? '编辑成功' : '新增成功')
						this.visible = false
						this.$emit('refresh')
					})
				}
			})
		},
		cancelUpdate() {
			this.visible = false
		},
	},
}
</script>
<style lang="scss" scoped>
::v-deep .el-form {
	margin-top: 14px;
	display: flex;
	flex-wrap: wrap;
	.el-form-item {
		flex: 0 0 calc((100% - 30px) / 2);
		margin-right: 0;
		&:nth-child(2n-1) {
			margin-right: 30px;
		}
		&.col-12 {
			flex: 0 0 100%;
			margin-right: 0;
		}
		.el-form-item__label {
			line-height: 14px;
			height: 14px;
			margin-bottom: 10px;
			font-weight: 600;
		}
		.el-select {
			width: 100%;
		}
	}
}
</style>
